import * as React from "react";
import "../output.css";
import TabBar from "../component/tabBar/TabBar";
import {Theme} from "react-daisyui";
import {Home} from "./Home";

function App() {
    return (
        <Theme className={"bg-base-100 text-base-content h-screen"}>
            <div className={"h-full"}>
                <TabBar href={""} tabLinks={[]}/>
                <Home/>
            </div>
        </Theme>
    );
}


export default App;
