import logo from "../assets/blue-fox logo.png";
import tool from "../assets/Screenshot 2024-08-15 221215.png";
import hub from "../assets/Screenshot 2024-08-15 235624.png";
import {IoLogoDocker} from "react-icons/io5";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {GoCheck, GoCopy} from "react-icons/go";
import React, {useState} from 'react'

export function Home() {
    const [copied, setCopied] = useState(false);

    return (
        <div className={"bg-base-100"}>
            <section className={"pt-10 sm:pt-2 md:pt-18"}>
                <div className={"mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0 lg:flex"}>
                    <div className={"m-auto"}>
                        <div className={"bg-primary justify-center items-center rounded-full " +
                            "w-40 h-40 " +
                            "md:w-60 md:h-60 " +
                            "lg:w-80 lg:h-80 " +
                            "flex m-auto"}>
                            <img className={"m-auto" +
                                "w-44 h-40 " +
                                "md:w-56 md:h-60 " +
                                "lg:w-72 lg:h-80 "
                            } src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className={"m-auto"}>
                        <h1 className={"text-center text-5xl font-bold sm:text-6xl lg:text-7xl"}>Blue-Fox</h1>
                        <h2 className={"text-center text-3xl font-bold sm:text-4xl lg:text-5xl"}>Solutions</h2>
                    </div>
                </div>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"flex"}>
                    <div className={"mx-auto"}>
                        <img className={"m-auto max-w-[45vw]"} src={tool} alt="Demo"/>
                    </div>
                    <div className={"mx-auto max-w-[45vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>Restful API</h2>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            This tool will allow users to quickly and easily test your internal and external API's.
                        </p>
                    </div>
                </div>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"flex"}>
                    <div className={"mx-auto max-w-[45vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>Demo</h2>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            If you would like to help try out the product there is a demo version of the application
                            available to run on <b><a className={"text-primary"}
                                                      href={"https://hub.docker.com/r/bluefox001/blue-fox"}>
                            <IoLogoDocker className={"inline"}/> hub.docker.com</a></b>.
                        </p>
                        <div className={"bg-accent mt-12 text-lg text-base-600 " +
                            "sm:text-xl pl-4 pt-1 pb-1 flex justify-between " +
                            "rounded"}>
                            <p>docker pull bluefox001/blue-fox:demo-v1</p>
                            <CopyToClipboard text={"docker pull bluefox001/blue-fox:demo-v1"}
                                             onCopy={() => copy(setCopied)}>
                                <div>
                                        <span className={"copy"}>
                                            <GoCheck className={!copied ? "hidden" : "show"}/>
                                            <GoCopy className={copied ? "hidden" : "show"}/>
                                        </span>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className={"mx-auto"}>
                        <img className={"m-auto max-w-[45vw]"} src={hub} alt="docker hub"/>
                    </div>
                </div>
            </section>
            <section className={"pt-28 h-[100vh] sm:pt-10 md:pt-16"}>
                <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>Feedback</h2>
                <iframe
                    title="Demo Feedback"
                    className={"h-full w-full"}
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdIU5AOJ8myvwEN1WSR1cgRirmMcC9ZTKfFGDLY6wJv3THrZg/viewform?embedded=true">Loading…
                </iframe>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
            </section>
        </div>
    );

    function copy(setCopied) {
        setCopied(true);

        new Promise(res => setTimeout(res, 1000))
            .then(r => setCopied(false));

    }
}